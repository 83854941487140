export const getUrlParams = ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }

    return params;
}


/**
 * 获取当前时间 时分秒
 */
export const getCurrentTime = ()=>{
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 确保月份是两位数
    const day = String(date.getDate()).padStart(2, '0'); // 确保日期是两位数
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0'); // 我也修正分钟为两位数
    const second = String(date.getSeconds()).padStart(2, '0'); // 以及秒为两位数
    const currentTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return currentTime;
}
