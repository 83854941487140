<template>
  <van-overlay
      :show="loadingOfWorkorderList">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="查看设备维护记录"
                 left-text="返回"
                 @click-left="router.back"/>

    <div class="page-container list">
      <van-list
          v-model:loading="list.loading"
          :finished="list.finished"
          :error.sync="list.error"
          :immediate-check="false"
          :offset="30"
          error-text="加载失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoad">
        <div class="card" v-for="item in list.list" :key="item.id" @click="onWorkorderItemClick(item.id)">
          <div class="handle">
            <van-icon name="manager-o" size="30"/>
            <span class="title">{{ item.handlerName }}</span>
          </div>
          <ul>
            <li class="number">
              <div>ID:{{ item.id }}</div>
            </li>
            <li class="workorder-info">
              <div>
                电话: {{ item.clientCellphone }}
              </div>
              <div>
                时间: {{ item.createTime }}
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </div>
  </div>
</template>

<!--用户keep-alive页面缓存-->
<script>
export default {
  name: "PreviewRecord"
};
</script>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";

import {workorderCustomerList} from "@/api/workorder";

const router = useRouter();
const list = reactive({
  loading: false,
  error: false,
  finished: false,
  current: 1,
  total: 0,
  list: [],
  dispatchList: []
});

const loadingOfWorkorderList = ref(false);

const urlParam = getUrlParam();

const onWorkorderItemClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/workorder", query: urlParam});
};

const onDispatchWorkorderClick = (id) => {
  urlParam.workorderId = id;
  router.push({path: "/unassigned-workorder", query: urlParam});
};

const requestWorkorderList = () => {
  const params = {
    resourceId: urlParam.id,
    current: list.current
  };
  loadingOfWorkorderList.value = true;
  workorderCustomerList(params).then(res => {
    const rowList = res.data.records;
    list.loading = false;
    loadingOfWorkorderList.value = false;
    list.total = res.data.total;
    if (rowList === null || rowList.length === 0) {
      list.finished = true;
      return;
    }
    list.list = list.list.concat(rowList);
    if (list.list.length >= list.total) {
      list.finished = false;
    }
  }).catch(err => {
    console.error("Request workorder list failed", err);
    loadingOfWorkorderList.value = false;
  });
};

// const requestDispatchWorkorder = () => {
//   dispatchWorkorderList({resourceId: urlParam.id, converted: 0}).then(res => {
//     list.dispatchList = res.data.records;
//   });
// };

const onLoad = () => {
  list.current++;
  requestWorkorderList();
};
onMounted(() => {
  validationUrlParam();
  requestWorkorderList();
  // requestDispatchWorkorder();
});
</script>

<style scoped>
/*.page-container {*/
/*  height: 100%;*/
/*  background-color: #f0f2f5;*/
/*  padding-bottom: 10px;*/
/*}*/

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 15px;
  margin: 24px 15px;
  border-radius: 8px;
  box-shadow: 0 5px 11px -3px rgba(0, 0, 0, .5);
}

.handle {
  flex: 2;
  text-align: center;
  border-right: 1px solid #999;
}

.handle img {
  width: 30px;
}

.title {
  display: inline-block;
  width: 80px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 2px;
}

.number {
  font-size: 18px;
  color: #0548ef;
}

.card ul {
  flex: 8;
  margin-left: 10px;
}

.card ul li {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.card ul li span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: dimgray;
}

.workorder-info {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #333;
}

</style>
